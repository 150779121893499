body::-webkit-scrollbar {
  width: 0.2em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(181, 181, 181);
  border-radius: 50px;
}



@font-face {
  font-family: SpaceGrotesk-Light;
  src: url(fonts/SpaceGrotesk-Light.otf);
}

@font-face {
  font-family: SpaceGrotesk-Regular;
  src: url(fonts/SpaceGrotesk-Regular.otf);
}

@font-face {
  font-family: SpaceGrotesk-Medium;
  src: url(fonts/SpaceGrotesk-Medium.otf);
}

@font-face {
  font-family: SpaceGrotesk-Bold;
  src: url(fonts/SpaceGrotesk-Bold.otf);
}


/* ---------------- About Page-----------------*/

.Mainview {
  display: flex;
  flex-direction: column;
  max-width: 700px;
  margin: 10px auto;
  padding: 10px;
  font-family: 'Sora', sans-serif;

}

.Main {
  color: #ffffff;
  display: flex;
  margin: 0px;
  flex-direction: column;
  padding: 20px;
  animation: fadeIn 2s;
}

.Page-Heading {
  font-size: 32px;
  color: white;
  font-family: SpaceGrotesk-Medium;
  font-weight: 400;
  animation: fadeIn 2s;
}


.Description {
  font-size: 20px;
  font-weight: 400;
  color: rgb(181, 181, 181);
  line-height: 1.4;
  animation: fadeIn 2s;
  font-family: SpaceGrotesk-Regular;
}

.Highlight {
  font-family: SpaceGrotesk-Bold;
  animation: color-change 5s infinite;
}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes color-change {
  0% {
    color: white;
  }

  25% {
    color: rgb(190, 248, 223);
  }

  50% {
    color: rgb(192, 216, 252);
  }

  75% {
    color: rgb(252, 162, 166);
  }

  100% {
    color: white;
  }
}



/* ---------------- Navbar ----------------*/


.Navbar {
  position: sticky;
  padding-top: 10px;
  top: 0;
  max-width: 700px;
  margin-top: 0px;
  margin: auto;
  color: rgb(181, 181, 181);
  background-color: black;
  z-index: 1000;
}

.Nav {
  display: flex;
  padding: 20px;
  margin: 20px 10PX;
  border-radius: 20px;
  justify-content: space-between;
  align-items: center;
  column-gap: 14px;
  color: white;
  background-color: rgb(10, 10, 10);
  font-family: SpaceGrotesk-Bold;
}

@media only screen and (max-width: 600px) {
  .Nav {
    padding: 18px;
  }
}

.Pages,
.Links {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 14px;
}

.Link,
.Page a {
  width: auto;
  height: auto;
  cursor: pointer;
  text-decoration: none;
}


.nav-link {
  color: rgb(190, 190, 190);
  text-decoration: none;
}

.nav-link:hover {
  color: rgb(225, 225, 225);
}

.activated {
  color: white;
  text-decoration: none;
}


/* ---------------- Projects ----------------*/

.Projects {
  color: white;
  justify-items: center;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 20px;
  animation: fadeIn 2s;
}

.Title {
  font-size: 22px;
  font-weight: 600;
  font-family: SpaceGrotesk-Medium;
}

.Decription {
  font-size: medium;
  color: rgb(181, 181, 181);
  font-size: 18px;
  font-family: SpaceGrotesk-Regular;
}

.Project {
  margin-top: 22px;
  justify-content: center;
  align-items: center;
  padding: 15px;
  color: white;
  background-color: rgb(0, 0, 0);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 15px;
  font-family: SpaceGrotesk-Regular;
}

.Project:hover {
  background-color: #101111;
}

ul {
  list-style-type: none;
  padding: 0;
}

img {
  width: 100%;
  border-radius: 15px;
}

.ProjectGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  row-gap: 10px;
  line-height: 1.6;

}

.project-link {
  display: flex;
  gap: 20px;
}

hr {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, gray, rgba(0, 0, 0, 0));
}



/* ---------------- About ----------------*/

.profileImg {
  margin: 20px auto;
  width: 250px;
}

.aboutMe {
  font-size: 18px;
  font-family: SpaceGrotesk-Light;
  text-align: left;
  line-height: 1.8;
}

.aboutMe>a {
  color: #155AAA;
}


@media only screen and (max-width: 600px) {
  .ProjectGrid {
    grid-template-columns: 1fr;
    row-gap: 20px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}